<template>
  <div class="freshDiv">

    <div class="flow-row space-between">
      <div class="distTitle1">{{ $t("app.fresh_news_title") }}</div>
      <div class="fs-018" @click="searchMore">{{ $t("app.More") }}</div>
    </div>

    <div class="distTitle2">{{ $t("app.fresh_news_subtitle") }}</div>

    <el-carousel :autoplay="false" >
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <div class="distDiv">
          <div
              class="distItem"
              v-for="(item1, index1) in item" :key="index1"
              @click="toDetail(item1)"
          >
            <div class="imgDiv">
              <img :src="item1.tbAdvertorialInfo.banner" class="img" alt="" v-if="item1.blogType == 1" />
              <div v-else >
                <img :src="getVideoCover(item1.tbAdvertorialInfo.banner)" class="img" alt="" />
                <img src="../assets/img/play.png" class="videoPlay" >
              </div>
              <div class="title">
                <div class="desc">{{ item1.tbAdvertorialInfo.advertorialTitle }}</div>
                <div class="desc">{{ item1.tbAdvertorialInfo.content | filtersText }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "districtPart",
  props: ["districtList"],
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
            .replace(reg, "")
            .replace(
                /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
                function (all, t) {
                  return arrEntities[t];
                }
            );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
  data() {
    return {
      dataList: [],
    }
  },
  watch: {
    districtList: {
      handler(dataList) {
        const list = []
        if(dataList.length) {
          dataList.forEach((el, i) => {
            const index = Math.floor(i / 4);
            if(!list[index]) {
              list[index] = [];
            }
            list[index].push(el);
          });
        }
        this.dataList = list;
      }
    }
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "blockDetail",
        query: {
          id: item.advertorialId,
        },
      });
    },
    searchMore(){
      this.$router.push("/searchFresh")
      // this.$router.push({
      //     path:'/result',
      //     query:{
      //         searchKey:"",
      //         name:'c',
      //         index:'1'
      //     }
      // })
    },
    getVideoCover(videourl){
      console.log(videourl,'videourl');
      let result = videourl.substring(0,videourl.lastIndexOf('.'));
      return result+"_cover.jpeg"
    },
  },
};
</script>

<style scoped lang="less">
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.freshDiv {
  text-align: start;
  width: 1136px;
  margin: 0 auto;

  .distTitle1 {
    color: black;
    font-size: .28rem;
    font-weight: 600;
  }

  .distTitle2 {
    color: #707070;
    font-size: .16rem;
    padding: 12px 0;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }


  .distItem:hover {
    transform: translateY(-.04rem);
    transition-duration: 0.5s;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin-right: 16px;
    width: 272px;
    height: 270px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: .04rem;

    // &:first-child {
    //   margin-right: 0;
    // }

    // &:last-child {
    //   margin-right: 0.4rem;
    // }

    // .imgDiv:hover {
    //   box-shadow: 4px 4px 4px #ccc;
    // }

    .imgDiv {
      position: relative;
      width: 272px;
      border-radius: .16rem;
      cursor: grab;

      .title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        // height: 58px;
        // background: rgba(0, 0, 0, 0.5);
        background:linear-gradient(to top,rgba(0, 0, 0,.6) 0,rgba(0, 0, 0,.6) 75%,rgba(0, 0, 0,.0) 100%);
        width: 100%;
        color: white;
        text-align: start;
        padding: .08rem .18rem;
        box-sizing: border-box;
        min-width: 0;
        border-bottom-left-radius: .16rem;
        border-bottom-right-radius: .16rem;

        .desc {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:first-child {
            font-size: .2rem;
          }

          &:last-child {
            font-size: .15rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
            overflow: hidden;
            margin-top: .1rem;
            min-height: 0.34rem;
          }
        }
      }

      .img {
        display: flex;
        width: 272px;
        height: 270px;
        object-fit: cover;
        border-radius: .16rem;
      }
      .videoPlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        fill: none;
        color: #FFF;
        pointer-events: none;
        opacity: 0.7;
        transition: opacity .3s;
        width: 1rem;
      }
    }
  }
}
/deep/ .el-carousel__indicators--horizontal {
  display: none;
}
</style>
