<template>
  <div class="homePage" ref="scro">
    <!-- <nav-bar :ifBlack="false"></nav-bar> -->
    <category-bar @goSearch="goSearch"></category-bar>
    <el-home-banner :bannerList="bannerList"></el-home-banner>
    <search-btn></search-btn>
    <district-part :districtList="districtList" v-show="districtList!=null && districtList.length>0"></district-part>
    <!-- <Swiper :list="districtList"/> -->
    <video-part :videoList="videoList" v-show="videoList!=null && videoList.length>0"></video-part>
    <freshNews :districtList="freshList" v-show="freshList!=null && freshList.length>0"></freshNews>
    <ad-space :adv="fixedAdv"></ad-space>
    <shop-part :shopList="shopList" v-show="shopList!=null && shopList.length>0"></shop-part>
    <scenic-part :scenicList="scenicList" v-show="scenicList!=null && scenicList.length>0"></scenic-part>
    <ticket-part :ticketList="ticketList" v-show="ticketList!=null && ticketList.length>0"></ticket-part>
    <travel-story-part :travelStoryList="travelStoryList"  v-show="travelStoryList!=null && travelStoryList.length>0"></travel-story-part>
    <img src="../../assets/img/pc_home_logo.jpg" class="bestImg" />
    <div class="trendingDiv">
      <div class="trendTitle">{{ $t("app.TrendingTravel") }}</div>
      <!-- <div class="placeTogo">{{ $t("app.Places_Go") }}</div> -->
      <div class="par_title">
        <!-- <div
          class="placeTitle"
          v-for="(item, index) in footerList"
          :key="index"
          @click="toPlaceDetail(item)"
        >
          {{ item.name }}
        </div> -->
        <van-cell>
          <div class="nav_list">
            <div
              class="list_nav"
              v-for="(item, index) in footerList"
              :key="index"
              @click="toPlaceDetail(item)"
            >
              <div class="head">
                <div class="portrait">
                  <img :src="item.img" alt="" srcset="" />
                  <span class="icon">
                    <img
                      src="../../assets/img/area2.png"
                      alt=""
                      srcset=""
                    />
                  </span>
                </div>
                <div class="list_describe">
                  <h3>{{ item.name }}</h3>
                  <p class="text-title">
                    <span>{{ item.desc | filtersText }}</span>
                  </p>
                  <p class="list_view">
                    {{ item.viewa }} {{ $t("app.reviews") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper from '../../components/swiper.vue'
import { Swipe, SwipeItem, Toast, Tab, Tabs, Dialog } from "vant";
import NavBar from "../../components/basicComponents/navBar";
import CategoryBar from "../../components/basicComponents/categoryBar";

import apis from "../../utils/apis";
import AdSpace from "../../components/adSpace";
import SearchBtn from "../../components/basicComponents/searchBtn";
import elHomeBanner from "../../components/elHomeBanner";
import DistrictPart from "../../components/districtPart";
import ScenicPart from "../../components/scenicPart";
import ShopPart from "../../components/shopPart";
import TicketPart from "../../components/ticketPart";
import VideoPart from "../../components/videoList";
import TravelStoryPart from "../../components/travelStoryPart";
import logo_black from "../../assets/img/logo_black.png";
import bestChoice from "../../assets/img/bestChoice.png";
import FreshNews  from "../../components/freshNews"


import Vue from "vue";

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);
let { indexRecommend, indexBanner, indexVideo, SearchAll, getFreshBlogList } = apis;

export default {
  components: {
    // Swiper,
    TravelStoryPart,
    TicketPart,
    ShopPart,
    ScenicPart,
    DistrictPart,
    elHomeBanner,
    SearchBtn,
    AdSpace,
    CategoryBar,
    NavBar,
    VideoPart,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    FreshNews,
  },

  data() {
    return {
      bannerList: [],
      value1:
        localStorage.getItem("language") == null
          ? "en"
          : localStorage.getItem("language"),
      option1: [
        { text: this.$t("app.en"), value: "en" },
        { text: this.$t("app.jp"), value: "jp" },
        { text: this.$t("app.cn"), value: "cn" },
        { text: this.$t("app.hk"), value: "hk" },
      ],

      logo: logo_black,
      bestChoice: bestChoice,

      active: 0,

      districtList: [],

      scenicList: [],
      shopList: [],

      ticketList: [],
      travelStoryList: [],

      fixedAdv: {},

      videoList: [],

      footerList: [],

      freshList:[],
    };
  },

  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },

  methods: {
    initData() {
      //1-area 2-views 4-intro 5-shop 6-ticket 0-all 7-freshNews
      this.getRecommendList("1");
      this.getRecommendList("2");
      this.getRecommendList("4");
      this.getRecommendList("5");
      this.getRecommendList("6");
      // this.getRecommendList("7")
      this.getFreshBlogList();
      this.getBannerList();
      this.getVideoList();
      // this.getFooterList();
    },
    // switch language
    changeLang(value) {
      localStorage.setItem("language", value);
      this.$i18n.locale = value;
      window.location.reload();
      window.scrollTo(0, 0);
      // this.getBannerList()
    },
    getVideoList() {
      let params = {
        "status": 0
      };
      indexVideo({ ...params }, (data) => {
        if (data.rows) {
          this.videoList = data.rows;
        }
      });
    },
    //get banner list   and fixed adv info
    getBannerList() {
      let params = {};
      indexBanner({ ...params }, (data) => {
        if (data.rows) {
          this.bannerList = [];
          for (let i = 0; i < data.rows.length; i++) {
            if (data.rows[i].sort.toString() != "0") {
              this.bannerList[Number(data.rows[i].sort) - 1] = data.rows[i];
            } else {
              this.fixedAdv = data.rows[i];
            }
          }
        }
      });
    },

    getFooterList() {
      let params = {
        searchType: '1',
        pageNum: 1,
        pageSize: 300,
      };
      let shareArr = ["1000+","2000+","3000+","4000+","5000+","6000+","7000+","8000+","9000+"];
      SearchAll({...params}).then(res => {
        this.footerList = res.data.data.list;
        this.footerList.forEach((it) => {
          it.viewa = shareArr[Math.floor(Math.random() * shareArr.length)];
        });
      });
    },

    getRecommendList(type) {
      let params = {
        type: type,
      };
      indexRecommend({ ...params }).then(res => {
        if (type == "1") {
          this.districtList = res.data.data;
        } else if (type == "5") {
          this.shopList = res.data.data;
        } else if (type == "2") {
          this.scenicList = res.data.data;
        } else if (type == "6") {
          this.ticketList = res.data.data;
          let shareArr = [
            "1000+",
            "2000+",
            "3000+",
            "4000+",
            "5000+",
            "6000+",
            "7000+",
            "8000+",
            "9000+",
          ];
          this.ticketList.forEach((it) => {
            it.viewa = shareArr[Math.floor(Math.random() * shareArr.length)];
          });
          // console.log(this.ticketList)
        } else if (type == "4") {
          this.travelStoryList = res.data.data;
        } else if (type == "7") {
          // this.freshList = res.data.data;
        }
      });
    },
    getFreshBlogList() {
      getFreshBlogList({}, response => {
        this.freshList = response.data;
      })
    },
    goSearch(name, index) {
      this.$router.push({
        path: "/result",
        query: {
          name: name,
          index: index,
        },
      });
    },

    toPlaceDetail(item) {
      this.$router.push({
        path: "essayDetail",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },

    sorry() {
      this.$toast("TO TOP");
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

  },

  created() {
    this.$forceUpdate();
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/css/homePage.less");
.homePage {
  background: white;
  //height: 100vh;
  // width: 1136px;
  // margin: 0 auto;

  .bottomDiv {
    background-color: #faf1ed;
    padding: 0.2rem 0;

    a {
      color: black;
      text-align: left;
      margin-left: 0.4rem;
      margin-top: 0.2rem;
      font-size: 0.3rem;
      display: flex;
      width: fit-content;
    }

    .footerDiv {
      padding-left: 0.4rem;
      padding-bottom: 2rem;
      display: flex;
      align-items: center;

      .logo {
        width: 2.1rem;
      }

      a {
        color: black;
        text-align: left;
        margin-left: 0.4rem;
        margin-top: 0.1rem;
        font-size: 0.26rem;
        display: flex;
        text-decoration: underline;
        width: fit-content;
      }
    }
  }

  ::v-deep .van-dropdown-menu__bar {
    margin: 0.6rem 0.4rem;
    height: 0.6rem;
    box-shadow: none;
    border: 0.01rem #000 solid;
    border-radius: 0.1rem;

    .van-dropdown-menu__item {
      justify-content: start;
    }

    .van-dropdown-menu__title {
      color: black;
      text-align: left;
      width: 7.1rem;
      padding-left: 0.3rem;

      &::after {
        border-color: transparent transparent black black;
        right: 0.3rem;
      }
    }
  }

  ::v-deep .van-dropdown-item__content {
    max-height: 100%;

    .van-dropdown-item__option {
      padding: 0.2rem 0.6rem;
    }
  }

  .welcome {
    margin: 0.5rem 0;
    width: 100%;
    text-align: center;
    font-size: 0.24rem;
  }

  .trendingDiv {
    display: none;
    text-align: left;
    // padding: 0.4rem 0.4rem;
    font-size: 0.18rem;
    font-weight: 500;
    width: 1136px;
    margin: 60px auto;

    .trendTitle {
      // font-size: 0.5rem;
      // font-weight: 700;
      // margin-bottom: 0.5rem;
      font-size: 35px;
      margin-bottom: 40px;
      font-weight: 600;
    }

    .placeTogo {
      width: fit-content;
      border-bottom: 0.03rem solid black;
      padding-bottom: 0.2rem;
    }

    .par_title {
      height: 600px;
      overflow-y: auto;
    }

    .placeTitle {
      padding-top: 20px;
    }
  }

  .bestImg {
    width: 100%;
    display: block;
  }
}
// .par_title::-webkit-scrollbar-track-piece {
//   background-color: rgba(0, 0, 0, 0);
//   border-left: 1px solid rgba(0, 0, 0, 0);
// }

.par_title::-webkit-scrollbar {
  width: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.par_title::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-height: 28px;
}
.list_nav .head {
  padding: 0 !important;
}
::v-deep .el-popup-parent--hidden {
  padding-right: 0 !important;
}
</style>
