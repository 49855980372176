<template>
  <div class="ticketDiv">

    <div class="flow-row space-between margin-auto">
      <div class="distTitle1">{{ $t("app.homeTitle4") }}</div>
      <div class="fs-018" @click="searchMore">{{ $t("app.More") }}</div>
    </div>

    <div class="distTitle2">{{ $t("app.homeSubtitle4") }}</div>

    <el-carousel :autoplay="false">
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <div class="distDiv">
          <div
            class="distItem"
            v-for="(item1, index1) in item"
            :key="index1"
            @click="toDetail(item1)"
          >
            <div class="ticketInfo">
              <img :src="item1.img" class="img" alt="" />
              <div class="ticketName" style="font-size: .2rem;">{{ item1.name }}</div>
              <div class="ticketName" style="font-size: .14rem; color: #595757">
                {{ item1.reviews || 0 }} {{ $t("app.reviews") }}
              </div>
              
              <div class="price" v-if="Number(item1.extra.price) == 0">{{ $t('app.noInventory') }}</div>
              <div class="price" v-else>{{ $t("app.money") }}{{ priceFormat(item1.extra.price) }}</div>
              <!-- <div class="price" v-else>{{ $t("app.money") }}{{ item1.price }}</div> -->
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "ticketPart",
  props: ["ticketList"],
  data() {
    return {
      dataList: [],
    };
  },
  watch: {
    ticketList: {
      handler(dataList) {
        const list = []
        if(dataList.length) {
          dataList.forEach((el, i) => {
            const index = Math.floor(i / 4);
            if(!list[index]) {
              list[index] = [];
            }
            list[index].push(el);
          });
        }
        this.dataList = list;
      }
    }
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "orderXq",
        query: {
          type: item.type,
          id: item.id,
        },
      });
    },
    searchMore(){
      const _this = this
      this.$router.push({
          path:'/result',
          query:{
              searchKey:"",
              name:'f',
              index:'6'
          }
      })
    }
  },
};
</script>

<style scoped lang="less">
.ticketDiv {
  margin-top: 0.4rem;
  text-align: start;
  background: #faf1ed;
  color: black;
  padding-top: 0.6rem;
  padding-bottom: 0.4rem;

  .distTitle1 {
    font-size: .28rem;
    font-weight: 600;
  }

  .distTitle2 {
    color: #707070;
    font-size: .16rem;
    padding: 12px 0;
    width: 1136px;
    margin: 0 auto;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    width: 1136px;
    margin: 0 auto;
  }

  .distItem:hover {
    transform: translateY(-.04rem);
    transition-duration: 0.5s;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin-right: 16px;
    width: 272px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: .04rem;

    // &:first-child {
    //   margin-left: 0.4rem;
    // }

    // &:last-child {
    //   margin-right: 0.4rem;
    // }

    .ticketInfo {
      width: 272px;
      display: flex;
      flex-direction: column;
      color: black;
      cursor: grab;

      .ticketName {
        width: 272px;
        text-align: start;
        box-sizing: border-box;
        color: black;
        background: #fff;
        padding: 0.1rem 0.2rem 0;

        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      .price {
        color: black;
        font-size: .18rem;
        text-align: start;
        box-sizing: border-box;
        background: #fff;
        padding: 0.07rem 0.2rem;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .img {
        display: flex;
        width: 272px;
        height: 235px;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
}
/deep/ .el-carousel__container {
  position: relative;
  height: 360px;
  width: 1136px;
  margin: 0 auto;
  white-space: nowrap;
}
/deep/ .el-carousel__indicators--horizontal {
  display: none;
}
</style>
