<template>
  <div class="shopDiv">

    <div class="flow-row space-between margin-auto">
      <div class="distTitle1">{{ $t("app.homeTitle2") }}</div>
      <div class="fs-018" @click="searchMore">{{ $t("app.More") }}</div>
    </div>

    <div class="distTitle2">{{ $t("app.homeSubtitle2") }}</div>

    <el-carousel :autoplay="false">
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <div class="distDiv">
          <div
            class="distItem"
            v-for="(item1, index1) in item" :key="index1"
            @click="toDetail(item1)"
          >
            <div class="shopInfo">
              <img :src="item1.img.split(',')[0]" class="img" alt="" />
              <div class="shopName">{{ item1.name }}</div>
              <div class="shopName" style="display: none;">
                {{ $t("app.Rating") }} : {{ getRandomNumber() }}
              </div>
            <div class="loc">{{ item1.extra.address }}</div>
          </div>
          </div>
      </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "shopPart",
  props: ["shopList"],
  data() {
    return {
      dataList: [],
    };
  },
  watch: {
    shopList: {
      handler(dataList) {
        const list = []
        if(dataList.length) {
          dataList.forEach((el, i) => {
            const index = Math.floor(i / 3);
            if(!list[index]) {
              list[index] = [];
            }
            list[index].push(el);
          });
        }
        this.dataList = list;
      }
    }
  },
  methods: {
    getRandomNumber() {
      var randomNumber = Math.random() * 0.9 + 4.0;
      return randomNumber.toFixed(1);
    },
    toDetail(item) {
      this.$router.push({
        path: "detailsPage",
        query: {
          // type: item.type,
          type: "5",
          id: item.id,
        },
      });
    },
    searchMore(){
      const _this = this
      this.$router.push({
          path:'/result',
          query:{
              searchKey:"",
              name:'e',
              index:'5'
          }
      })
    }
  },
  filters: {
    getFristText(str) {
      let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
      return rText.replace(/<[^>]+>/g, "").trim();
    },
    filtersText(val) {
      if (val != null && val != "") {
        let reg = new RegExp("<.+?>", "g");
        let arrEntities = {
          lt: "<",
          gt: ">",
          nbsp: " ",
          amp: "&",
          quot: '"',
          ldquo: "“",
          mdash: "—",
          rdquo: "”",
        };
        return val
          .replace(reg, "")
          .replace(
            /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
            function (all, t) {
              return arrEntities[t];
            }
          );
        // } else return '';
        // if (val != null && val != '') {
        //     let reg = /<img.*?(?:>|\/>)/gi;
        //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
        //     val = names ? names.join('') : '';
        //     return val;
        // } else return '';
      }
    },
  },
};
</script>

<style scoped lang="less">
.shopDiv {
  margin-top: 0.6rem;
  text-align: start;
  background: #faf1ed;
  color: black;
  padding: .4rem 0;

  .distTitle1 {
    font-size: .28rem;
    font-weight: 600;
    // width: 1136px;
    // margin: 0 auto;
  }

  .distTitle2 {
    color: #707070;
    width: 1136px;
    font-size: .16rem;
    padding: 12px 0;
    margin: 0 auto;
  }

  // .distDiv {
  //   width: 100%;
  //   overflow: hidden;
  //   overflow-x: auto;
  //   white-space: nowrap;
  //   width: 1136px;
  //   margin: 0 auto;
  // }

  .distItem:hover {
    transform: translateY(-.04rem);
    transition-duration: 0.5s;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin-right: 16px;
    width: 368px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: .04rem;

    // &:first-child {
    //   margin-left: 0.4rem;
    // }

    // &:last-child {
    //   margin-right: 0.4rem;
    // }

    .shopInfo {
      width: 368px;
      display: flex;
      flex-direction: column;
      cursor: grab;
      .shopName {
        background: #fff;
        padding: 0.1rem 0.2rem 0;
        width: 368px;
        font-size: .18rem;
        text-align: start;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .loc {
        background: #fff;
        padding: .07rem .2rem;
        line-height: 30px;
        width: 368px;
        font-size: .15rem;
        text-align: start;
        box-sizing: border-box;
        height: 72px;

        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .img {
        display: flex;
        width: 368px;
        height: 235px;
        object-fit: cover;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
      }
    }
  }
}
/deep/ .el-carousel__container {
  position: relative;
  height: 400px;
  width: 1136px;
  margin: 0 auto;
  white-space: nowrap;
}
/deep/ .el-carousel__indicators--horizontal {
  display: none;
}
</style>
