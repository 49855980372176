<template>
  <div class="travelStoryDiv">

    <div class="flow-row space-between margin-auto">
      <div class="distTitle1">{{ $t("app.homeTitle5") }}</div>
      <div class="fs-018" @click="searchMore">{{ $t("app.More") }}</div>
    </div>
    <div class="distTitle2">{{ $t("app.homeSubtitle5") }}</div>

    <el-carousel :autoplay="false">
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <div class="distDiv">
          <div class="distItem" v-for="(item1, index1) in item" :key="index1" @click="toDetail(item1)">
            <div class="shopInfo">
              <img :src="item1.img" class="img" alt="">
              <div class="shopName">{{ item1.name }}
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>


export default {
  name: "travelStoryPart",
  props: ["travelStoryList"],
  data() {
    return {
      dataList: [],
    }
  },
  watch: {
    travelStoryList: {
      handler(dataList) {
        const list = []
        if(dataList.length) {
          dataList.forEach((el, i) => {
            const index = Math.floor(i / 3);
            if(!list[index]) {
              list[index] = [];
            }
            list[index].push(el);
          });
        }
        this.dataList = list;
      }
    }
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "essayDetail",
        query: {
          type: item.type,
          id: item.id
        }
      });
    },
    searchMore(){
      const _this = this
      this.$router.push({
          path:'/result',
          query:{
              searchKey:"",
              name:'b',
              index:'4'
          }
      })
    }

  },

}
</script>

<style scoped lang="less">
.travelStoryDiv {
  text-align: start;
  background: white;
  color: black;
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;

  .distTitle1 {
    font-size: 0.28rem;
    font-weight: 600;
  }
  .distTitle2 {
    color: #707070;
    font-size: .16rem;
    padding: 12px 0;
    width: 1136px;
    margin: 0 auto;
  }

  .margin-auto {
    margin: 0 auto;
  }

  .distDiv {
    overflow: hidden;
    white-space: nowrap;
    width: 1136px;
    margin: 0 auto;
  }

  .distItem:hover {
    transform: translateY(-.04rem);
    transition-duration: 0.5s;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin-right: 16px;
    width: 368px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: .04rem;

    // &:first-child {
    //   margin-left: 0.4rem;
    // }

    // &:last-child {
    //   margin-right: 0.4rem;
    // }


    .shopInfo {
      width: 368px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: grab;
      .shopName {
        margin-top: .2rem;
        width: 368px;
        font-size: .2rem;
        text-align: start;
        box-sizing: border-box;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .img {
        display: flex;
        width: 368px;
        height: 270px;
        object-fit: cover;
        border-radius: 16px;
      }
    }
  }
}
/deep/ .el-carousel__container {
  position: relative;
  height: 360px;
  width: 1136px;
  margin: 0 auto;
  white-space: nowrap;
}
/deep/ .el-carousel__indicators--horizontal {
  display: none;
}
</style>
