<template>
  <el-carousel class="homePage-swipe" :interval="4000000" arrow="never">
    <el-carousel-item v-for="(item, index) in bannerList" :key="index" >
      <img :src="item.banner" >
    </el-carousel-item>
  </el-carousel>
</template>
<script>

export default {
  name: "elHomeBanner",
  props: ['bannerList'],
  data() {
    return {
    }
  },
  methods: {
    toDetail(item){
      if(item.url){
        if(item.url.indexOf("http://") != -1 || item.url.indexOf("https://" != -1)) {
          window.location.href = item.url;
        } else {
          window.location.href = window.location.protocol + '//' + item.url;
        }
      }else if(item.refId){
        this.$router.push({
          path: "essayDetail",
          query: {
            type: item.refType,
            id: item.refId,
          },
        });
      }
    }
  }
}
</script>

<style lang="less">
.homePage-swipe {
  width: 1136px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 348px;
    display: block;
    object-fit: cover;
  }

  .el-carousel__indicators--horizontal{
    .el-carousel__indicator--horizontal{
      background-color: white;
      opacity: 1;
      border: 1px #000 solid;
      border-radius: 6px;
      display: inline-block;
      padding: 1px 2px;
      margin-right: 14px;
    }

    .el-carousel__indicator--horizontal.is-active{
      width: 18px;
      border-radius: 6px;

    }

    .el-carousel__button{
      width: 2px;
      height: 3px;
      background: #ffffff;
      border-radius: 6px;
      opacity:1;
    }
  }
}


</style>
