<template>
    <div class="videoDiv" v-if="videoList && videoList.length > 0">

        <div class="flex_row">
            <div class="distTitle1">{{ $t('app.videoList') }}</div>
            <div class="fs-018" @click="searchMore">{{ $t("app.More") }}</div>
        </div>

      <el-carousel :autoplay="false">
        <el-carousel-item v-for="(item, index) in dataList" :key="index">
            <div class="distDiv">
                <div class="distItem" v-for="(item1, index1) in item" :key="index1">
                    <div class="shopInfo">
                        <VideoPlay class="video-player vjs-custom-skin" :videoCover="getVideoCover(item1.url)" :videoUrl="item1.url" :autoplay="false" :loop="false"
                            :muted="true" :width="560" :height="365" :type="'video'" :videoId="item1.id"/>
                        <div class="videoName">{{ item1.title }}
                        </div>
                    </div>
                </div>
            </div>
        </el-carousel-item>
      </el-carousel>
    </div>
</template>

   

<script>
import VideoPlay from "./video/ViedoPlayer.vue";

export default {
    name: "videoPart",
    components: {
        VideoPlay,
    },
    props: ["videoList"],
    data() {
        return {
            dataList: [],
        };
    },
    watch: {
      videoList: {
        handler(dataList) {
            const list = []
            if(dataList.length) {
              dataList.forEach((el, i) => {
                const index = Math.floor(i / 2);
                if(!list[index]) {
                  list[index] = [];
                }
                list[index].push(el);
              });
            }
            this.dataList = list;
        }
      }
    },
    methods: {
        searchMore(){
            this.$router.push('/videoList')
        },
        getVideoCover(videourl){
            // var reg1 = new RegExp(".mp4video","g"); 
            // var reg2 = new RegExp(".mp4","g"); 
            // let result = videourl.replace(reg2,'');
            // result = videourl.replace(reg1,'');
            let result = videourl.substring(0,videourl.lastIndexOf('.'));
            return result+"_cover.jpeg"
        },
    },
    filters: {
        getFristText(str) {
            let rText = str.substring(str.indexOf("<p"), str.lastIndexOf("</p"));
            return rText.replace(/<[^>]+>/g, "").trim();
        },
        filtersText(val) {
            if (val != null && val != "") {
                let reg = new RegExp("<.+?>", "g");
                let arrEntities = {
                    lt: "<",
                    gt: ">",
                    nbsp: " ",
                    amp: "&",
                    quot: '"',
                    ldquo: "“",
                    mdash: "—",
                    rdquo: "”",
                };
                return val
                    .replace(reg, "")
                    .replace(
                        /&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi,
                        function (all, t) {
                            return arrEntities[t];
                        }
                    );
                // } else return '';
                // if (val != null && val != '') {
                //     let reg = /<img.*?(?:>|\/>)/gi;
                //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
                //     val = names ? names.join('') : '';
                //     return val;
                // } else return '';
            }
        },
    },
};
</script>
<style scoped lang="less">
.videoDiv {
    text-align: start;
    background: white;
    color: black;
    padding-top: 55px;
    width: 1136px;
    margin: 0 auto;

    .flex_row {
        display: flex;
        justify-content: space-between;
        width: 1136px;
        margin: 0 auto;
        .distTitle1 {
            font-size: .28rem;
            font-weight: 600;
            padding-bottom: 15px;
        }
    }

    .distItem {
        height: 100%;
        display: inline-flex;
        background: transparent;
        margin-right: 16px;
        width: 560px;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        // &:first-child {
        //     margin-left: 0.4rem;
        // }

        // &:last-child {
        //     margin-right: 0.4rem;
        // }


        .shopInfo {
            width: inherit;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .videoName {
                margin-top: 0.2rem;
                line-height: 0.35rem;
                width: 100%;
                font-size: 0.2rem;
                text-align: start;
                box-sizing: border-box;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .img {
                display: flex;
                width: 5.35rem;
                height: 3.42rem;
                object-fit: cover;
            }
        }
    }
}

/deep/ .el-carousel__container {
  position: relative;
  height: 450px;
  width: 1136px;
  margin: 0 auto;
  white-space: nowrap;
}
/deep/ .el-carousel__indicators--horizontal {
  display: none;
}
.distDiv{
  height: 100%;
}

</style>
  

  
